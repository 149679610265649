import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import uuidv1 from "uuid/v1"

import "./static/styles/shopPage.css"

const divStyles = {
  backgroundColor: `#ff5722`,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  paddingTop: `0rem`,
  width: `100%`,
}

const prodHolder = {
  backgroundColor: `#f6f6f6`,
  //   borderBottom: `6px solid #FF5722`,
  boxShadow: `5px 6px 6px 2px #f6f6f6`,
  color: `#464545`,
  display: `grid`,
  fontSize: `0.8rem`,
  fontWeight: `500`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  justifyContent: `center`,
  lineHeight: `1.3rem`,
  margin: `1.5rem 2rem`,
  placeItems: `center`,
  textAlign: `center`,
  //   boxShadow: `6px 6px 3px 2px #FF5722`,
}

const formHolder = {
  width: `80%`,
  margin: `0 auto`,
  textTransform: `uppercase`,
  fontSize: `unset`,
}

export default () => {
  const [size, setSize] = useState([])
  const [quantity, setQuantity] = useState([])
  // const [bagButtonState, setBagButtonState] = useState(true)

  const data = useStaticQuery(graphql`
    query {
      dataJson {
        products {
          id
          title
          volume {
            size
            q
            color
          }
          startDate
          endDate
          type
          price
          img
          description
          prodCode
        }
      }
    }
  `)

  const getFirstKey = arr => {
    return arr[0].size === null ? "Color" : "Size"
  }

  const getSzClrOptions = arr => {
    // console.log(arr)
    let searchBy = (arr[0].size === null ? "Color" : "Size").toLowerCase()
    // console.log("searchBy", searchBy, typeof searchBy)
    let stmntArr = []
    for (let index = 0; index < arr.length; index++) {
      const output = arr[index]
      const stmnt = output[searchBy]
      stmntArr.push(stmnt)
    }
    // console.log(stmntArr)
    return stmntArr
  }

  // const getQuantityOptions = arr => {
  //   console.log(arr)

  //   const searchBy = "q"

  //   let stmntArr = []
  //   for (let index = 0; index < arr.length; index++) {
  //     const output = arr[index]
  //     const stmnt = output[searchBy]
  //     // let val = 0
  //     // for (let index = 1; index <= stmnt; index++) {
  //     //   val = index
  //     //   stmntArr.push(val)
  //     // }
  //     stmntArr.push(stmnt)
  //   }

  //   console.log(stmntArr, "stmntArr")
  //   return stmntArr
  // }

  const addToStateArray = (prodId, e, stateVar, stateArr) => {
    if (e !== null) {
      let itemObject
      let existingStatus = false
      let origStateArr = stateArr
      console.log("state var", stateVar)

      for (let i in origStateArr) {
        if (origStateArr[i].item === prodId) {
          console.log("existing found", origStateArr[i][stateVar])
          origStateArr[i][stateVar] = e.target.value
          existingStatus = true
          console.log("returning updated state", origStateArr)
          return origStateArr
        }
      }

      if (!existingStatus) {
        if (stateVar === "sizeColor") {
          itemObject = { item: prodId, sizeColor: e.target.value }
        } else if (stateVar === "quantity") {
          itemObject = { item: prodId, quantity: e.target.value }
        }
        origStateArr.push(itemObject)
        console.log("modified stateArr", origStateArr)
        return origStateArr
      }
    }

    console.log("evt is null")
    for (let i in stateArr) {
      if (stateArr[i].item === prodId) {
        console.log("valueParse", stateArr[i].stateVar)
        return stateArr[i].stateVar
      }
      return
    }
  }

  console.log(data.dataJson.products)
  const origDataArr = data.dataJson.products

  const listProds = origDataArr.map(prod => {
    return (
      <div style={prodHolder} key={uuidv1()} className="prodHolder">
        <img src={prod.img} alt={prod.title} style={{ margin: `auto` }}></img>
        <h4 style={{ marginTop: `0.5rem` }}>{prod.title}</h4>
        <span>${prod.price}</span>
        <span
          style={{
            padding: `0.5rem 0.8rem`,
            textAlign: `justify`,
            fontWeight: `500`,
          }}
        >
          {prod.description}
        </span>
        <Form style={formHolder}>
          <Form.Group controlId="formSize">
            <Form.Label>{getFirstKey(prod.volume)}</Form.Label>
            <Form.Control
              as="select"
              name="size"
              type="text"
              value={addToStateArray(prod.id, null, "sizeColor", size)}
              onChange={e =>
                setSize(addToStateArray(prod.id, e, "sizeColor", size))
              }
              placeholder="SELECT"
              required
              maxLength="25"
              autoComplete="size"
              style={{ fontSize: `unset` }}
            >
              <option value="0">SELECT</option>
              {getSzClrOptions(prod.volume).map(elem => {
                // console.log(elem, "elem")
                return (
                  <option value={elem} key={uuidv1()}>
                    {elem}
                  </option>
                )
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formQuantity">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              as="select"
              name="quantity"
              type="number"
              value={addToStateArray(prod.id, null, "quantity", quantity)}
              onChange={e =>
                setQuantity(addToStateArray(prod.id, e, "quantity", quantity))
              }
              placeholder="SELECT"
              required
              maxLength="25"
              autoComplete="size"
              style={{ fontSize: `unset` }}
            >
              <option value="0">SELECT</option>
              {/* {getQuantityOptions(prod.volume).map(elem => {
                return (
                  <option value={elem} key={uuidv1()}>
                    {elem}
                  </option>
                )
              })} */}
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {/* <Button
          style={{
            marginBottom: `0.0rem`,
            backgroundColor: `#FF5722`,
            border: `none`,
            fontWeight: `600`,
            height: `2.4rem`,
            margin: `0.5rem auto`,
          }}
          disabled={bagButtonState}
          onClick={() => {
            alert("Added To Bag")
          }}
        >
          Add To Bag
        </Button> */}
        <Link
          to="/checkout"
          state={{ productCode: prod.prodCode, size: size, quantity: quantity }}
        >
          <Button
            style={{
              marginBottom: `0.0rem`,
              backgroundColor: `#FF5722`,
              border: `none`,
              fontWeight: `600`,
              height: `2.4rem`,
              margin: `0.5rem auto`,
            }}
            onClick={() => {
              console.log(prod.id)
            }}
            id={prod.id}
          >
            EXPRESS CHECKOUT
          </Button>
        </Link>
      </div>
    )
  })

  return (
    <div style={divStyles}>
      <div className="compShop__main">{listProds}</div>
    </div>
  )
}
