import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShopPageComp from "../components/shopPageComp"
import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"

import "../components/static/styles/shopPage.css"

export default class Shop extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Online Shop Yoga Leggings" />
        <div className="mainShop">
          <img src={sideImageL} alt="sideBanner" className="sideImageLShop" />
          <ShopPageComp />
          <img src={sideImageR} alt="sideBanner" className="sideImageRShop" />
        </div>
      </Layout>
    )
  }
}
